<template>
    <div class="blog_block">

        <div class="container" v-if="status === 'pending'">
            <div class="py-2">
                <div class="step_skeleton">
                    <div class="placeholder skeleton"></div>
                    <div class="placeholder skeleton"></div>
                    <div class="placeholder skeleton"></div>
                    <div class="placeholder skeleton"></div>
                </div>
            </div>       
        </div>
       
        <div class="container" v-else-if="status === 'success'">
            <div>
                <h4 class="mb-3 text-center font-active">Stay In Vogue</h4>
                <div>
                    <swiper 
                    :modules="[SwiperAutoplay]"
                    :loop="true"
                    :autoplay="{
                        delay: 2500,
                        disableOnInteraction: false,
                    }"
                    :breakpoints="{
                        0: {
                            slidesPerView: 1.5,
                            centeredSlides: true,
                            slidesPerGroup: 1,
                        },
                        512: {
                            slidesPerView: 2,
                            slidesPerGroup: 1,
                            centeredSlides : false,
                        },
                        768: {
                            slidesPerView: 3,
                            slidesPerGroup: 1,
                            centerMode: false
                        },
                        992: {
                            slidesPerView: 4,
                            slidesPerGroup: 1,
                            centerMode: false
                        },

                    }"
                    class="mySwiper py-3"
                    >
                    
                        <swiper-slide v-for="list in listing" :key="list.id">
                            <div class="blog_box" >
                                <NuxtLink :to="localePath('/blog/'+ list.slug)" class="radious_eight">
                                    <img class="radious_four" loading="lazy" width="337" height="325" :src="list.image" :alt="list.title">
                                    <div class="blog_text">
                                        <h3 class="mb-0">{{list.title}}</h3>
                                    </div>
                                </NuxtLink>
                            </div>
                        </swiper-slide>
                    </swiper>
                </div>
                <div class="view_all_btn mt-3">
                    <NuxtLink :to="localePath('/blog')" class="btn-s-animation">
                        <span>Read All Blogs</span>
                    </NuxtLink>
                </div>
            </div>
        </div>

        <div class="" v-else>

        </div>
    </div>
</template>


<script setup>

    const props = defineProps(['type']);
    const localePath = useLocalePath()
    const listing = ref(false)

    const { data, status,  } = await useFetch(useCountryPath('/config/custom/product?module_type=' + props.type))

    if(data.value && data.value.data?.blogs){
        listing.value = data.value.data?.blogs
    }
   
</script>


<style scoped>
.container{
    max-width: 1470px;
    width: 100%;
    padding: 3% 0;
}
.blog_block{
    background: #faf9f7;
  
}
.step_skeleton {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.skeleton {
    height: 361px;
    width: 345px;
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.3);
    -webkit-mask-image: radial-gradient(white, black);
    mask-image: radial-gradient(white, black);
    cursor: wait;
    overflow: hidden;
}
.view_all_btn {
    display: flex;
    align-items: center;
    justify-content: center;
}

.view_all_btn a {
    color: #000;
    display: inline-block;
    background: #fff!important;
    border: 1px solid #000;
    margin: 0 auto;
    padding: 9px 25px;
    text-transform: uppercase;
    min-width: 150px;
    text-align: center;
    border-radius: 4px;
}

.view_all_btn a span{
    font-size: 14px!important;
    font-weight: 600;
    line-height: 1.5px;
}
.fade-enter-active {
    transition: opacity 0.5s ease;
}

.fade-enter, .fade-leave-to {
    opacity: 0;
}
h4{
    font-size: 30px;
}
a{
    color: #000;
    display: inline-block;
}
.blog_text{
    padding: 6% 0 2%;
    text-align: left;
}
h3 {
    font-size: 16px;
    text-align: center;
    color: #353535;
    line-height: 1.3;
}
p {
    font-size: 14px;
    color: #6a6161;
    padding: 3% 0;
}
h5 {
    font-size: 14px;
    font-weight: 600;
}
h5 span {
    border: 1px solid #000;
    padding: 4px 7px;
    display: inline-block;
    margin-left: 8px;
}
.blog_box{
    padding: 0 5%;
    
}
.blog_box a {
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
    background: #fff;
    padding: 5%;
}

.blog_box a:hover{
    box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.15);
}

@media(max-width: 992px){
    .skeleton {
        height: 237px;
        width: 200px;
    }
    .skeleton:nth-child(n+4) {
        display: none;
    }
    .container{
        padding: 3%;
    }
}

@media(max-width: 767px){
    .view_all_btn {
      
        padding-bottom: 20px;
    }
    h4{
        font-size: 24px;
        padding-top: 20px;
    }
    .row{
        text-align: center;
    }
    h3{
        font-size: 14px;
    }
    .blog_text {
        max-width: 100%;
        text-align: center;
        margin: 0 auto;
    }
    .blog_box{
        padding: 0 4%; 
    }
    .skeleton:nth-child(n+3) {
        display: none;
    }
    .step_skeleton{
        justify-content: space-evenly;
    }
    .container{
        padding: 0;
    }
}
@media (max-width: 500px){
    .step_skeleton .skeleton:nth-child(n + 2) {
      display: none;
    }
  }
</style>